import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Symbol`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Definition`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`#`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of occurrences of a subject. For example, number of contacts or number of leads.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`∑`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total of all values. Sum of all the values in a count column.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Average`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`µ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of all values divided by the number of units. Units can be days, months or other.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DAverage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DAvg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of all values divided by the number of units. Units can be days, months or other.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`95th percentile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.95`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Value below which 95% of the data falls.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of occurrences of a subject in relation to the total number of occurrences. For example, percentage of contacts or percentage of leads.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Highest value in a set of data.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`m`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lowest value in a set of data.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The following sections explain the metrics listed in the previous table with examples. Note that you can click on the images to enlarge them.`}</p>
    <h2>{`Count and Percentage`}</h2>
    <p>{`The following image shows the number and percentage of contacts that the contact center handled during four weeks:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6d1255f6eac0ec2fd29c9a7d806fd199/e9957/metrics-contacts-percentage.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABFUlEQVQoz41Ri27DIAzM/3/o1C0BzNNgSG6yu1bqqklDOnSO7TO+bDlneO9Ra8XjXNf1b2if9wEpJYgINr2ICJEIzjnEGFXyRfj3oPf4DuVb74zdR9yOgFoKmk6MGbtP6L0bjpBwOwhaq3CUcHPRODPj00V87GR8K6XgcB7OB7TWoHEgQqBoXC0hSnCBjNdaQDHjcMHyurL23752y293D7wFYwwr4taw5rCJGg9hCJefGgGLCmXL9yGIqaCUe7w9/FhrYc75Y87ENfnplayGk93T2zoD5tWNs5zIfD5rbWUVUq/0hcqlNwhnzLkwZKD1iFEOiAysuZC7B49qjyhtIOSOpX36l3Xlh+h5nlb0jvnH91eoxjfhonAZ4wr84wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6d1255f6eac0ec2fd29c9a7d806fd199/e93cc/metrics-contacts-percentage.webp 300w", "/static/6d1255f6eac0ec2fd29c9a7d806fd199/b0544/metrics-contacts-percentage.webp 600w", "/static/6d1255f6eac0ec2fd29c9a7d806fd199/68fc1/metrics-contacts-percentage.webp 1200w", "/static/6d1255f6eac0ec2fd29c9a7d806fd199/469e7/metrics-contacts-percentage.webp 1488w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6d1255f6eac0ec2fd29c9a7d806fd199/eed55/metrics-contacts-percentage.png 300w", "/static/6d1255f6eac0ec2fd29c9a7d806fd199/7491f/metrics-contacts-percentage.png 600w", "/static/6d1255f6eac0ec2fd29c9a7d806fd199/8537d/metrics-contacts-percentage.png 1200w", "/static/6d1255f6eac0ec2fd29c9a7d806fd199/e9957/metrics-contacts-percentage.png 1488w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6d1255f6eac0ec2fd29c9a7d806fd199/8537d/metrics-contacts-percentage.png",
              "alt": "count-and-percentage",
              "title": "count-and-percentage",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Week of contact`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week of contact is the aggregation field. It represents the week number when the contact occurred. Values in the other columns are aggregated by the week of contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`# Contacts`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of contacts that the contact center handled in each week.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`# Success`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Of the handled contacts, this represents the number of contacts that the contact center classified as successful.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`% Contacts`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Percentage of contacts that the contact center handled each week.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`% Success`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Of the handled contacts, this represents the percentage of contacts that the contact center classified as success each week.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`% Weekly Success`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is a calculated metric and, of the number of contacts handled each week, it represents the percentage of contacts that the contact center classified as success in each week.`}</td>
        </tr>
      </tbody>
    </table>
    <h3><strong parentName="h3">{`% Percentage`}</strong></h3>
    <p>{`Contact Center and Engagement Studio calculates the percentage as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`    Number of occurrences of a subject
% = ---------------------------------- * 100 
       Total number of occurrences
`}</code></pre>
    <p>{`The following table shows how the Contact Center and Analytics Studio calulates `}<em parentName="p">{`% Contacts`}</em>{` and `}<em parentName="p">{`% Success`}</em>{` are columns:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`% Contacts`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`% Success`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`% Weekly Success`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`(Number of weekly contacts/Total number of contacts) * 100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(Number of weekly contacts classified as success/Total number of contacts classified as success) * 100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(Number of weekly contacts classified as success/Number of weekly contacts) * 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`(26/366) * 100 = `}<b>{`7.10 %`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(26/366) * 100 = `}<b>{`7.10 %`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(26/26) * 100 = `}<b>{`100%`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`(114/366) * 100 = `}<b>{`31.15 %`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(111/366) * 100 = `}<b>{`30.33 %`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(111/114) * 100 = `}<b>{`97.37%`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`(122/366) * 100 = `}<b>{`33.33 %`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(110/366) * 100 = `}<b>{`30.05 %`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(110/122) * 100 = `}<b>{`90.16%`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`(104/366) * 100 = `}<b>{`28.42 %`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(95/366) * 100 = `}<b>{`27.05 %`}</b></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(95/104) * 100 = `}<b>{`91.35%`}</b></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Daily Average`}</h2>
    <p>{`The following image shows the number of contacts that the contact center handled by day:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "926px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ac3a39a2f1b955003de8e10c51cde877/f7b72/metrics-contacts-davg-daily.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "109.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVQ4y51UDW/qMAzM//+LDz1pEzDKZ1Py1aTpTecuUEo2tFWyKHZ8Pl9cq7ZtcTgcwN/VaoWUEl494zii6zporbHdbnE+n+Gck1wVQsD1ehXAy+UC5zy8vxuTl5ZzRt/3YtZaECPGKH41DINU22w2OB6PaHY7NM0O6/VaqtcAad8xV2TBSqyQYkRnPLTxQp++Odu5nbTBpbNPfkXK7N9ahxA83j8O+Pf2AWuNtFPi05np3RiD/+sGb5u9vJcYTQDZctFQty3cDIwJFJ86z30802ktefOYYrsEol7UsWkaeB/gnROxWf10Oglo3wcEP/lpzKPujInPO6iawEXT5QWMY0bur/K7zKN/8O0EWEaBxltfAtJ/e08e49f/kiOxnJCHCDU/XA6RPkGfxoUFyXCRQ7I5BYmp2tCS3Zzh3TJytLeWb8aWk5e2qwyXLT/Eo1swzBNgbzCE7hmQGnJAq4DULNU+xy+G0T63/CPgy5bNHxj25rllMoxuavlXDAlIDastB2FZZciv49tLmc3hA2C00y3XxqbstzpgqGsogAaq7LHyKb1kGM3Pc0i9CFKMQATkLHIn3m1AShHR6YU/IQ0ZKRhEr6H2+/3D3itDXVb8g3Gj2LYSiwhWI3gDxQoE4k4j2yLB7x5qP7X/CW4Zv249IwatAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ac3a39a2f1b955003de8e10c51cde877/e93cc/metrics-contacts-davg-daily.webp 300w", "/static/ac3a39a2f1b955003de8e10c51cde877/b0544/metrics-contacts-davg-daily.webp 600w", "/static/ac3a39a2f1b955003de8e10c51cde877/655d4/metrics-contacts-davg-daily.webp 926w"],
              "sizes": "(max-width: 926px) 100vw, 926px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ac3a39a2f1b955003de8e10c51cde877/eed55/metrics-contacts-davg-daily.png 300w", "/static/ac3a39a2f1b955003de8e10c51cde877/7491f/metrics-contacts-davg-daily.png 600w", "/static/ac3a39a2f1b955003de8e10c51cde877/f7b72/metrics-contacts-davg-daily.png 926w"],
              "sizes": "(max-width: 926px) 100vw, 926px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ac3a39a2f1b955003de8e10c51cde877/f7b72/metrics-contacts-davg-daily.png",
              "alt": "average-daily",
              "title": "average-daily",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Day of contact (DD)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of contact is the aggregation field. It represents the day of the the month when the contact occurred. For example, the images shows that the contact center handled contacts in day and 3, but there weren't handled contacts in day 2.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`# Contacts`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of contacts that the contact center handled in each week.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`DAvg Contacts`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Daily average. Average number of contacts  per day.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Contact Center and Engagement Studio calculates the daily average as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`       Number of occurrences of a subject
DAvg = ----------------------------------
              Last day - First day
`}</code></pre>
    <p>{`The following table shows the Contact Center and Analytics Studio calculates `}<em parentName="p">{`DAvg Contacts`}</em>{` column:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Day of contact (DD)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DAvg Contacts`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26 / (19-1) = `}<b>{`1.44`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / (19-1) = `}<b>{`0.78`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18 / (19-1) = `}<b>{`1`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23 / (19-1) = `}<b>{`1.28`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28 / (19-1) = `}<b>{`1.56`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / (19-1) = `}<b>{`0.78`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17 / (19-1) = `}<b>{`0.94`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21 / (19-1) = `}<b>{`1.17`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26 / (19-1) = `}<b>{`1.44`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`27 / (19-1) = `}<b>{`1.5`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21 / (19-1) = `}<b>{`1.17`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21 / (19-1) = `}<b>{`1.17`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6 / (19-1) = `}<b>{`0.33`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / (19-1) = `}<b>{`0.78`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26 / (19-1) = `}<b>{`1.44`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24 / (19-1) = `}<b>{`1.33`}</b></td>
        </tr>
      </tbody>
    </table>
    <p>{`The following image shows the number that are shown in the previous image aggregated by week:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "926px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3d26b34b22037b9cc10cc54f6aed4bf4/f7b72/metrics-contacts-davg-weekly.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABXElEQVQoz4VTi26DMBDL/3/ipE7bOlZRnoGEJJDgzdelZbTVkE4BO+ccvovq+x5VVYHr4XDAsiz471nXFcMwQGuN0+mEruswTZPkKu89xnEUwbZtYYwBMeccYoySvI+UEkIIEsyl2DzPgismESyKAk3T4PN4RHUu5ZvCuaJ9PKtcsRKexHKXeYZxAdo4xGW5VroP4p020KO945S1FhLTBO8dPr4qvLydYK1B5vhLt3crtrweS7wXZ3nf8iJIkMYyBq0lKW8gR/NpyxbjgYPuxfstJ03hBnqWffQ+yKnkuDZNLaIXCy64/HbXoa4z54RT7MzeWG5m1/YNWFNC9Brrmu4ak4IRTmURCueVYlvBa6SIFMYdxjwgzVZE1T6RY8Su57m6BQ+LUsVf/CIYw4joB6iyLMVU+phH4tGouF//3Fg/GKOAaWzhTA/FatgdXqV87R4N8jWe8JHV/gz2Nx6aqAxXkfYmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3d26b34b22037b9cc10cc54f6aed4bf4/e93cc/metrics-contacts-davg-weekly.webp 300w", "/static/3d26b34b22037b9cc10cc54f6aed4bf4/b0544/metrics-contacts-davg-weekly.webp 600w", "/static/3d26b34b22037b9cc10cc54f6aed4bf4/655d4/metrics-contacts-davg-weekly.webp 926w"],
              "sizes": "(max-width: 926px) 100vw, 926px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/3d26b34b22037b9cc10cc54f6aed4bf4/eed55/metrics-contacts-davg-weekly.png 300w", "/static/3d26b34b22037b9cc10cc54f6aed4bf4/7491f/metrics-contacts-davg-weekly.png 600w", "/static/3d26b34b22037b9cc10cc54f6aed4bf4/f7b72/metrics-contacts-davg-weekly.png 926w"],
              "sizes": "(max-width: 926px) 100vw, 926px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/3d26b34b22037b9cc10cc54f6aed4bf4/f7b72/metrics-contacts-davg-weekly.png",
              "alt": "average-weekly",
              "title": "average-weekly",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The previous image shows contacts aggregated by week. However, the Contact Center and Engagement Studio calculates the daily average using the number of days.`}</p>
    <p>{`The following table shows the Contact Center and Analytics Studio calculates `}<em parentName="p">{`DAvg Contacts`}</em>{` column:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Week of contact`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DAvg Contacts`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26 / (19-1) = `}<b>{`1.44`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`114 / (19-1) = `}<b>{`6.33`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`122 / (19-1) = `}<b>{`6.78`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64 / (19-1) = `}<b>{`3.56`}</b></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Sum, Average, Minimum, and Maximum`}</h2>
    <p>{`The following images show the sum, average, minimum, maximum, and 95th percentile of the duration of contacts aggregated by day and by CoreMedia CID:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fa976db72d83b5f667adcabeeb053165/d3a93/metrics-contacts-duration-by-contact.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABfElEQVQ4y7VS2W7kMAzL//9n0Wkn8X3JzgwLalZB293XFRBI1kGTcrbgPVprGEMgIpgieD6f+G6/jlf9PBeGCGQMjN6x1sIWU4YPESEEOB/w/rlr05wTuQ30IVhzqk+1Q2RqrWht4jxPdJmo/TWzHc4jxITWKnxMuN09QoioteL97vC5O/TWsLuAt9uOXApiTPjYPe6HRykZLkS83e5IOWNj80vyQAwBa4rGlJ5TxJyidQ4+zoWUEsboWk9aX6i1oNX6ksxdkCrNe3/F3I9zTuNSijKmWU4JxKgx18X907bH43GBsHABroUQvMYEM0D28FFEGSbN0V+ALJIqfc5ZY2NoA5Tce9ce5gyQzE2BEVFAO1CO3XSuiePYNeZFNnwchwJSMtnaqnj+wZDGnfxfyX+WbpLtUgNkvyn4AWisKOf7K3v/9ytT3u9Xpr8AyYgH/vF2E2ORrju1PEEZM8cZMiYQ/xKugWfWN8phwALZ0vNjjoOM6a3Hcv+qE/AL4ef3KhL1YL0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fa976db72d83b5f667adcabeeb053165/e93cc/metrics-contacts-duration-by-contact.webp 300w", "/static/fa976db72d83b5f667adcabeeb053165/b0544/metrics-contacts-duration-by-contact.webp 600w", "/static/fa976db72d83b5f667adcabeeb053165/68fc1/metrics-contacts-duration-by-contact.webp 1200w", "/static/fa976db72d83b5f667adcabeeb053165/a2303/metrics-contacts-duration-by-contact.webp 1800w", "/static/fa976db72d83b5f667adcabeeb053165/c97c6/metrics-contacts-duration-by-contact.webp 1866w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fa976db72d83b5f667adcabeeb053165/eed55/metrics-contacts-duration-by-contact.png 300w", "/static/fa976db72d83b5f667adcabeeb053165/7491f/metrics-contacts-duration-by-contact.png 600w", "/static/fa976db72d83b5f667adcabeeb053165/8537d/metrics-contacts-duration-by-contact.png 1200w", "/static/fa976db72d83b5f667adcabeeb053165/d2cc9/metrics-contacts-duration-by-contact.png 1800w", "/static/fa976db72d83b5f667adcabeeb053165/d3a93/metrics-contacts-duration-by-contact.png 1866w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fa976db72d83b5f667adcabeeb053165/8537d/metrics-contacts-duration-by-contact.png",
              "alt": "percentile-max-min-sum",
              "title": "percentile-max-min-sum",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Day of contact (DD)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of contact is the aggregation field. It represents the day of the the month when the contact occurred. For this example, we selected day 15, 16, and 17.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Coremedia CID`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CoreMedia unique identifier of the contact. Using the `}<em parentName="td">{`CoreMedia CID`}</em>{` to aggregate results allows to view the duration of each contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`# Contacts`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of contacts that the contact center handled per day and per contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`∑ Duration`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of the durations of all contacts the Contact Center and Analytics Studio handled  per day and per contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`µ Duration`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of the durations of all contacts the Contact Center and Analytics Studio handled  per day and per contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`m Duration`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum duration of a contact  per day and per contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`M Duration`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum duration of a contact  per day and per contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`.95 Duration`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration of the contacts in .95 percentile  per day and per contact.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The following image shows the same data as the image above, but aggregated by day:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7c12bde8c11b81169a9df6cea5d25198/f9260/metrics-contacts-duration-by-week.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0ElEQVQY041Q0W4DMQi7///RVetdEkiAQG/1BNXeFwnFENsEjjkniAitNdz3jf+c9/tz/9w3VBXbDCILIoIjC611jDHKWMyhtvF6vWDbsdQKewSmGNyjctEN3V6m2wPmgTkXjrUWrj6qU8bj2XB1Lnx1wtmoOvOceJwDgxgqgrMTnleHqmDwxNd3w3ldOJI8JyONE29TMA203uHuYKL6ve+NMTqYCXtv9N6R6zKzWpep1oQHM1cxxRGBCC+RiNZof++JU5BmySPm0mRkPeLD/QVpUIN+CsZ3BwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7c12bde8c11b81169a9df6cea5d25198/e93cc/metrics-contacts-duration-by-week.webp 300w", "/static/7c12bde8c11b81169a9df6cea5d25198/b0544/metrics-contacts-duration-by-week.webp 600w", "/static/7c12bde8c11b81169a9df6cea5d25198/68fc1/metrics-contacts-duration-by-week.webp 1200w", "/static/7c12bde8c11b81169a9df6cea5d25198/a2303/metrics-contacts-duration-by-week.webp 1800w", "/static/7c12bde8c11b81169a9df6cea5d25198/67c76/metrics-contacts-duration-by-week.webp 1864w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7c12bde8c11b81169a9df6cea5d25198/eed55/metrics-contacts-duration-by-week.png 300w", "/static/7c12bde8c11b81169a9df6cea5d25198/7491f/metrics-contacts-duration-by-week.png 600w", "/static/7c12bde8c11b81169a9df6cea5d25198/8537d/metrics-contacts-duration-by-week.png 1200w", "/static/7c12bde8c11b81169a9df6cea5d25198/d2cc9/metrics-contacts-duration-by-week.png 1800w", "/static/7c12bde8c11b81169a9df6cea5d25198/f9260/metrics-contacts-duration-by-week.png 1864w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7c12bde8c11b81169a9df6cea5d25198/8537d/metrics-contacts-duration-by-week.png",
              "alt": "percentile-max-min-sum",
              "title": "percentile-max-min-sum",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Day of contact (DD)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of contact is the aggregation field. It represents the day of the the month when the contact occurred. For this example, we selected day 15, 16, and 17.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`# Contacts`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of contacts that the contact center handled per day.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`∑ Duration`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sum of the durations of all contacts the Contact Center and Analytics Studio handled  per day.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`µ Duration`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Average of the durations of all contacts the Contact Center and Analytics Studio handled  per day.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`m Duration`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minimum duration of a contact  per day.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`M Duration`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Maximum duration of a contact  per day.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`.95 Duration`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration of the contacts in .95 percentile  per day.`}</td>
        </tr>
      </tbody>
    </table>
    <h3><strong parentName="h3">{`Sum`}</strong></h3>
    <p>{`Contact Center and Engagement Studio calculates the sum as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`∑ = Durarion1 + Durarion2 + ... + DurarionN
`}</code></pre>
    <p>{`In the second image, the Contact Center and Analytics Studio calculates the sum of the durations of the contacts as follows:`}</p>
    <p><inlineCode parentName="p">{`∑ = 00:42:48 + 01:55:56 = 02:38:44`}</inlineCode></p>
    <h3><strong parentName="h3">{`Average`}</strong></h3>
    <p>{`Contact Center and Engagement Studio calculates the average as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`    Durarion1 + Durarion2 + ... + DurarionN
∑ = ---------------------------------------
                        N
`}</code></pre>
    <p>{`In the previous equation, `}<inlineCode parentName="p">{`N`}</inlineCode>{` is the number of records.`}</p>
    <p>{`The following table shows the Contact Center and Analytics Studio calculates `}<em parentName="p">{`µ Duration`}</em>{` column:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><em parentName="th">{`Day of Contact (DD)`}</em></th>
          <th parentName="tr" {...{
            "align": null
          }}><em parentName="th">{`µ Duration`}</em></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(00:00:00 + 00:04:39 + 00:11:25 + 00:04:09 + 00:17:33 + 00:05:02)/6 = `}<b>{`00:07:43`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(00:06:59 + 00:05:38 + 00:04:12 + 00:05:48 + 00:09:32 + 00:07:56 + 00:08:45 + 00:09:38 + 00:04:49 + 00:06:49 + 00:11:11 + 00:16:46 + 00:06:23 + 00:11:30)/14 = `}<b>{`00:08:45`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(00:00:00 + 00:04:39 + 00:11:25 + 00:04:09 + 00:17:33 + 00:05:02 + 00:06:59 + 00:05:38 + 00:04:12 + 00:05:48 + 00:09:32 + 00:07:56 + 00:08:45 + 00:09:38 + 00:04:49 + 00:06:49 + 00:11:11 + 00:16:46 + 00:06:23 + 00:11:30)/20 = `}<b>{`00:07:56`}</b></td>
        </tr>
      </tbody>
    </table>
    <h3><strong parentName="h3">{`Minimum`}</strong></h3>
    <p>{`The minimum duration in day 15 is `}<inlineCode parentName="p">{`00:00:00`}</inlineCode>{` and in day 17 is `}<inlineCode parentName="p">{`00:04:12`}</inlineCode>{`.`}</p>
    <p>{`The global minimum duration is `}<inlineCode parentName="p">{`00:00:00`}</inlineCode>{`.`}</p>
    <h3><strong parentName="h3">{`Maximum`}</strong></h3>
    <p>{`The maximum duration in day 15 is `}<inlineCode parentName="p">{`00:17:33`}</inlineCode>{` and in day 17 is `}<inlineCode parentName="p">{`00:16:46`}</inlineCode>{`.`}</p>
    <p>{`The global maximum duration is `}<inlineCode parentName="p">{`00:17:33`}</inlineCode>{`.`}</p>
    <h2><strong parentName="h2">{`95th percentile`}</strong></h2>
    <p>{`The following image includes the 95th percentile of the duration of contacts aggregated by day and by CoreMedia CID:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fa976db72d83b5f667adcabeeb053165/d3a93/metrics-contacts-duration-by-contact.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABfElEQVQ4y7VS2W7kMAzL//9n0Wkn8X3JzgwLalZB293XFRBI1kGTcrbgPVprGEMgIpgieD6f+G6/jlf9PBeGCGQMjN6x1sIWU4YPESEEOB/w/rlr05wTuQ30IVhzqk+1Q2RqrWht4jxPdJmo/TWzHc4jxITWKnxMuN09QoioteL97vC5O/TWsLuAt9uOXApiTPjYPe6HRykZLkS83e5IOWNj80vyQAwBa4rGlJ5TxJyidQ4+zoWUEsboWk9aX6i1oNX6ksxdkCrNe3/F3I9zTuNSijKmWU4JxKgx18X907bH43GBsHABroUQvMYEM0D28FFEGSbN0V+ALJIqfc5ZY2NoA5Tce9ce5gyQzE2BEVFAO1CO3XSuiePYNeZFNnwchwJSMtnaqnj+wZDGnfxfyX+WbpLtUgNkvyn4AWisKOf7K3v/9ytT3u9Xpr8AyYgH/vF2E2ORrju1PEEZM8cZMiYQ/xKugWfWN8phwALZ0vNjjoOM6a3Hcv+qE/AL4ef3KhL1YL0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fa976db72d83b5f667adcabeeb053165/e93cc/metrics-contacts-duration-by-contact.webp 300w", "/static/fa976db72d83b5f667adcabeeb053165/b0544/metrics-contacts-duration-by-contact.webp 600w", "/static/fa976db72d83b5f667adcabeeb053165/68fc1/metrics-contacts-duration-by-contact.webp 1200w", "/static/fa976db72d83b5f667adcabeeb053165/a2303/metrics-contacts-duration-by-contact.webp 1800w", "/static/fa976db72d83b5f667adcabeeb053165/c97c6/metrics-contacts-duration-by-contact.webp 1866w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fa976db72d83b5f667adcabeeb053165/eed55/metrics-contacts-duration-by-contact.png 300w", "/static/fa976db72d83b5f667adcabeeb053165/7491f/metrics-contacts-duration-by-contact.png 600w", "/static/fa976db72d83b5f667adcabeeb053165/8537d/metrics-contacts-duration-by-contact.png 1200w", "/static/fa976db72d83b5f667adcabeeb053165/d2cc9/metrics-contacts-duration-by-contact.png 1800w", "/static/fa976db72d83b5f667adcabeeb053165/d3a93/metrics-contacts-duration-by-contact.png 1866w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fa976db72d83b5f667adcabeeb053165/8537d/metrics-contacts-duration-by-contact.png",
              "alt": "percentile",
              "title": "percentile",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To calculate the 95th percentile, Contact Center and Analytics Studio sorts the contact durations as shown in the following table:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Day of contact (DD)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Duration`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Position (k)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:00:00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:04:09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:04:12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:04:39`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:04:49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:05:02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:05:38`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:05:48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:06:23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:06:49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:06:59`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:07:56`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:08:45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:09:32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:09:38`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:11:11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:11:25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:11:30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:16:46`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`19`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:17:33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Then, it uses the following formulas:`}</p>
    <pre><code parentName="pre" {...{}}>{`I = .95 * (N - 1) + 1
k = floor(I)
d = decimal_part(I)

.95 Percentile = Xk + d * (Xk+1 - Xk)
`}</code></pre>
    <p>{`Where:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`I`}</inlineCode>{` is the position of the 95th percentile.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`N`}</inlineCode>{` is the number of values in the list.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`k`}</inlineCode>{` is the position in the list.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`d`}</inlineCode>{` is the value used for the interpolation.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Xk`}</inlineCode>{` is the value in the position `}<inlineCode parentName="li">{`k`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Xk+1`}</inlineCode>{` is the value in the position `}<inlineCode parentName="li">{`k+1`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`Note that, if the value calculated for `}<inlineCode parentName="p">{`I`}</inlineCode>{` is an integer, the value of the 95th percentile is the value in the position `}<inlineCode parentName="p">{`Xi`}</inlineCode>{`.`}</p>
    <p>{`For the values in the table above, Contact Center and Analytics Studio calculates the 95th percentile as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`I = .95 * (20 - 1) + 1 = 19.05
k = floor(19.05) = 19
d = decimal_part(19.05) = 0.05

.95 Percentile = X19 + 0.05 * (X20 - X19) = 0:16:46 + 0.05 * (0:17:33 - 0:16:46) = 0:16:49
`}</code></pre>
    <p>{`The following image shows the percentile for day 15 and day 17:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7c12bde8c11b81169a9df6cea5d25198/f9260/metrics-contacts-duration-by-week.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0ElEQVQY041Q0W4DMQi7///RVetdEkiAQG/1BNXeFwnFENsEjjkniAitNdz3jf+c9/tz/9w3VBXbDCILIoIjC611jDHKWMyhtvF6vWDbsdQKewSmGNyjctEN3V6m2wPmgTkXjrUWrj6qU8bj2XB1Lnx1wtmoOvOceJwDgxgqgrMTnleHqmDwxNd3w3ldOJI8JyONE29TMA203uHuYKL6ve+NMTqYCXtv9N6R6zKzWpep1oQHM1cxxRGBCC+RiNZof++JU5BmySPm0mRkPeLD/QVpUIN+CsZ3BwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7c12bde8c11b81169a9df6cea5d25198/e93cc/metrics-contacts-duration-by-week.webp 300w", "/static/7c12bde8c11b81169a9df6cea5d25198/b0544/metrics-contacts-duration-by-week.webp 600w", "/static/7c12bde8c11b81169a9df6cea5d25198/68fc1/metrics-contacts-duration-by-week.webp 1200w", "/static/7c12bde8c11b81169a9df6cea5d25198/a2303/metrics-contacts-duration-by-week.webp 1800w", "/static/7c12bde8c11b81169a9df6cea5d25198/67c76/metrics-contacts-duration-by-week.webp 1864w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7c12bde8c11b81169a9df6cea5d25198/eed55/metrics-contacts-duration-by-week.png 300w", "/static/7c12bde8c11b81169a9df6cea5d25198/7491f/metrics-contacts-duration-by-week.png 600w", "/static/7c12bde8c11b81169a9df6cea5d25198/8537d/metrics-contacts-duration-by-week.png 1200w", "/static/7c12bde8c11b81169a9df6cea5d25198/d2cc9/metrics-contacts-duration-by-week.png 1800w", "/static/7c12bde8c11b81169a9df6cea5d25198/f9260/metrics-contacts-duration-by-week.png 1864w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7c12bde8c11b81169a9df6cea5d25198/8537d/metrics-contacts-duration-by-week.png",
              "alt": "percentile-max-min-sum",
              "title": "percentile-max-min-sum",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Day 15`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Day of contact (DD)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Duration`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Position (k)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:00:00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:04:09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:04:39`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:05:02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:11:25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:17:33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For the values in the table above, Contact Center and Analytics Studio calculates the 95th percentile as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`I = .95 * (6 - 1) + 1 = 5.75
k = floor(5.25) = 5
d = decimal_part(5.25) = 0.75

.95 Percentile = X5 + 0.75 * (X6 - X5) = 0:11:25 + 0.75 * (0:17:33 - 0:11:25) = 0:16:01
`}</code></pre>
    <p><strong parentName="p">{`Day 17`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Day of contact (DD)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Duration`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Position (k)`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:04:12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:04:49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:05:38`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:05:48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:06:23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:06:49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:06:59`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:07:56`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:08:45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:09:32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:09:38`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:11:11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:11:30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0:16:46`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For the values in the table above, Contact Center and Analytics Studio calculates the 95th percentile as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`I = .95 * (14 - 1) + 1 = 13.35
k = floor(13.35) = 13
d = decimal_part(13.35) = 0.35

.95 Percentile = X13 + 0.35 * (X14 - X13) = 0:11:30 + 0.35 * (0:16:46 - 0:11:30) = 0:13:21
`}</code></pre>
    <h2>{`Calculating change rates in a multi-dimension metrics panel`}</h2>
    <p>{`The percentage below each metric represents the change rate between the values of the metric in two consecutive periods. To calculate the change rates of the metrics, Contact Center and Analytics Studio compares the values of the metrics in period `}<inlineCode parentName="p">{`N`}</inlineCode>{` and period `}<inlineCode parentName="p">{`N-1`}</inlineCode>{`.`}</p>
    <p>{`Contact Center and Analytics Studio calculates the change rates of the metrics based on the following formula:`}</p>
    <pre><code parentName="pre" {...{}}>{`     100 * (metric in period 2 - metric in period 1)
% =  ----------------------------------------------
                  metric in period 1
`}</code></pre>
    <p>{`In the following image, the metric panel shows the number of visits, pages requests, and unique visitors of the documentation site during the month of `}<inlineCode parentName="p">{`February 2025`}</inlineCode>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1dbd0b7742fc0407538ed2311af2108a/9e042/metric-create-result.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC9klEQVQ4y3WQ20+TZxzH3z/C7JgB2zQzFAVRDj2DqLHYw3uo0Pd9iyTSDS0zYzGbSptodFl2uzsTE93Fkl1su3Iu2bJDYiLdQa14SJgz4CoFhFJLt0Hpgc/S9zUNF+yXfC++eX7PJ5/nEUZHR+nefwBH115c7m7sri6sDlcttv+L04XV7qTdaqfD5qjtC5N/PCQYjdPiOcJbe/tp9gxgk9+mUxrCKg9hU4awKxHswQiOag5HcPZFsCpD+AaPcfL0Gd774DTHTowSiZ5AWCuW8A7HecWmsKU9wCcXP2fpWY70whLzmayRucWlWoy+kGHqyQyPpqbJZjPMpFJ89PEFxuIxhH/+XcH7zhiv2mW2tHn57OtvqU2pwqazvk5xZZXi2ppRs9lnnD9/jg9PnUJYWS3gG47xmkPmhQ4fl7/8xlgqVyqMT/zK5PRDs5fLRqqz/Hee7xI/MjOXNvr80wVG3j/J4PBxhGKpjP94jDqnzIudPq58de05oEI6P09uNW9KbRAsVkqklmcplEzDv2bnaA8O0OhREKomgWiMepfCS1Z/zbCwVjRvV0zbzGKG9JM0q4UC6xUTXyqZxo/Tszi1o+wSQwilcgUxGqOhS+Flm48vrn6/6bflC8v8Ofto07OlXA53+CitUr8JlEbiNLhl6twSZz+9xO0Hk1z/PcmNWxPcuHWHxO27/PDbL1xLXCeRvMt4coJEcoLx5B1u3rvP1Z9+xqkP0hYMmU+W341T5xKxeDTe7FGod/t5vVvkjR6RrftEtu0PsP2gjKU3yHaPSGOvhOWQRJNXYodPojkg09mvYQ1pCLl8HmkkRr1botGjYulV2enTaPZrtAQ0WkWN3bLOHkWnLajT0afT2a9jDenY1DAOLYxDD2PXdOyahvB0YRFxZIwGt2TAGj0hmg6F2OFVafartARUdokqu2WVPYpGe1Cjo0+rGVVBDl3HGTYjPE6lCETP0NBlAi29G4EhA9gqbQAeVg1gFWZTTaAzrOEa0HEf0fkP8Wy2PZU58h8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1dbd0b7742fc0407538ed2311af2108a/e93cc/metric-create-result.webp 300w", "/static/1dbd0b7742fc0407538ed2311af2108a/b0544/metric-create-result.webp 600w", "/static/1dbd0b7742fc0407538ed2311af2108a/68fc1/metric-create-result.webp 1200w", "/static/1dbd0b7742fc0407538ed2311af2108a/fff20/metric-create-result.webp 1484w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1dbd0b7742fc0407538ed2311af2108a/eed55/metric-create-result.png 300w", "/static/1dbd0b7742fc0407538ed2311af2108a/7491f/metric-create-result.png 600w", "/static/1dbd0b7742fc0407538ed2311af2108a/8537d/metric-create-result.png 1200w", "/static/1dbd0b7742fc0407538ed2311af2108a/9e042/metric-create-result.png 1484w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1dbd0b7742fc0407538ed2311af2108a/8537d/metric-create-result.png",
              "alt": "February",
              "title": "February",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the following image, the metric panel shows the number of visits, pages requests, and unique visitors of the documentation site during the month of `}<inlineCode parentName="p">{`January 2025`}</inlineCode>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fe06e30a3a8ad6c3549242ee2753b43f/b6335/metric-create-result-last-month.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACzElEQVQ4y32SW2tTaRSG95/wiIe5lKaHmNp2kibZUlpLahuTtNmnVKvggZSqFQWVUo14L15a9MKbQW8EHWZ+gIh4wk7StCY9Gq3VVKiBSiXdO80z7J1dFSwueOFbrI/ne9/FJ/T399PS2opPFBFFEb8o4vP78f9G6/Nmrw+3x4u7uSKP14eQTKaQ4pdxBXqpapEs1bTJVLdKVLeZZ4naAxJ17aZk9gYUXB0KNe0SbdHDHDsVp+9EnKMn4kh9xxEAes5cZ4c3yuamMEM37jA3/5Hx6RzZt/Nk5+bJzL4nM/eezOw7RicmSWWnSWVnSL/JsJj/RCqZ5GpimPMXLyEUi6uE+6+wzR1mU2MXt+8/Yr3KhsFGtWYYrCwvUywWrT6XyzE0dJnB8xcQdF2n+3SCHc0RtjR1ceveQ+uSrhtMTGdYyH+0eqNUsmTW8tevPBt9QaFQsPrs5BQnBwboOxVHKJfNyAl2eiNsdQcZsR0aRoncl3mWVgq/OFwt6WQ/z6AbutW/GhunrlPCGZQrO5TOJtjli7DNE2TEdlhc1e3cUFpbo/ClwGJ+ETNRea1sP1pZycuxcZwhBVdEtYGD19gtRtjuCXL3wb8b7i1fyJOeHd9wlp6apj6i0NCjVYDyuQpwtxhm+OYI6akZno6meJ5MW3qZmuDJf6M8Tr7m1diE5cjUi9QYqewkf/39Dw09Ck2SDVTOXWOnL0RVQGVPu8yedomqgISjQ6a6U6amS8J5SMEVUtkbknFFFOq7Ffb1KBaoMargljXcioaw8u0b8mCCXf4QjoBKVUDBYX7cTpXaLhVn0ISouMIq9RHVitUY1Sw3f9oQjxqjWdMsCUtLS0hnr1hxHR0qjoBC9UGV2k6VuqCK89A6TKOh+wfMdFQBxfDGYvh6NXy9MYQPCwtEzwzzx34b2GECKw7rgspPQNUGqhWg5Uz7DvMfjiEeifE/baiyX/8M9bEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fe06e30a3a8ad6c3549242ee2753b43f/e93cc/metric-create-result-last-month.webp 300w", "/static/fe06e30a3a8ad6c3549242ee2753b43f/b0544/metric-create-result-last-month.webp 600w", "/static/fe06e30a3a8ad6c3549242ee2753b43f/68fc1/metric-create-result-last-month.webp 1200w", "/static/fe06e30a3a8ad6c3549242ee2753b43f/f60ae/metric-create-result-last-month.webp 1486w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fe06e30a3a8ad6c3549242ee2753b43f/eed55/metric-create-result-last-month.png 300w", "/static/fe06e30a3a8ad6c3549242ee2753b43f/7491f/metric-create-result-last-month.png 600w", "/static/fe06e30a3a8ad6c3549242ee2753b43f/8537d/metric-create-result-last-month.png 1200w", "/static/fe06e30a3a8ad6c3549242ee2753b43f/b6335/metric-create-result-last-month.png 1486w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fe06e30a3a8ad6c3549242ee2753b43f/8537d/metric-create-result-last-month.png",
              "alt": "January",
              "title": "January",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Comparing the values of the metrics in `}<inlineCode parentName="p">{`February 2025`}</inlineCode>{` and `}<inlineCode parentName="p">{`January 2025`}</inlineCode>{`,Contact Center and Analytics Studio calculates change rates in the first image as follows:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Period`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`January 2025`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`February 2025`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`% Change`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`# Visits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.793`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3.369`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`+100 * (3.369 - 2.793) / 2.793 = 20.62%+`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`# Page Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12.902`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14.373`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`+100 * (14.373 - 12.902) / 12.902 = 11.40%+`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`# Unique Visitors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.427`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.734`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`+100 * (1.734 - 1.427) / 1.427 = 21.51%+`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      